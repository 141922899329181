import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import logo from "../images/logo.svg"
import cover from "../images/FBCover.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Hjem" />
    <section className="forside-logo-wrapper">
      <img className="forside-cover" src={cover} alt="BI-Esport" />
      <img className="forside-logo" src={logo} alt="BI-Esport" />
    </section>
    <section>
      <h3>Velkommen til BI-Esport!</h3>
      <p>
      BI-Esport tilbyder fællesskab om esport og udvikling af udøvere/spillere i CSGO.<br />
      Alle uanset alder er velkommen!
      </p>
    </section>
  </Layout>
)

export default IndexPage
